<template>
  <div class="productsbg1">
    <div class="">
      <div class="grid lg:grid-cols-2 grid-flow-row">
        <div class="productsDeco2"></div>
        <div class="productsDeco3">
          <div class="pt-48 lg:pr-20 productsDeco1">
            <h1 class="text-StemRed font-black text-xl text-left px-2">
              BEST DESTINATIONS AROUND THE WORLD
            </h1>
            <h2 class="text-StemBlue font-bold text-6xl text-left pt-6 pl-2">
              Produktet Tona and Lego Kits
            </h2>
            <p
              class="
                text-gray-400
                font-normal
                text-sm
                leading-loose
                text-left
                pt-7
                px-2
              "
            >
              Built Wicket longer admire do barton vanity itself do in it.
              Preferred to sportsmen it engrossed listening. Park gate sell they
              west hard for the.Built Wicket longer admire do barton vanity
              itself do in it. Preferred to sportsmen it engrossed listening.
              Park gate sell they west hard for the.Built Wicket longer admire
              do barton vanity itself do in it. Preferred to sportsmen it
              engrossed listening. Park gate sell they west hard for the.
            </p>
            <div class="grid lg:grid-cols-2 py-16 place-items-center">
              <div class=""></div>
              <div class="">
                <button
                  class="
                    rounded-lg
                    bg-StemYellow
                    hover:bg-transparent hover:text-StemYellow
                    text-white
                    border
                    hover:border-StemYellow
                    text-xl
                    font-normal
                    shadow-2xl
                    px-10
                    py-4
                  "
                >
                  Go to LEGO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
</script>

<style>

@media only screen and (min-width: 1025px) {
  .productsbg1 {
  background-image: url("./../../assets/images/ProductsGroup.png");
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: 19%;
}

.productsDeco1 {
  background-image: url("./../../assets/Svg/ProductsDeco1.svg");
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: 60%;
}
.productsDeco2 {
  background-image: url("./../../assets/Svg/ProductsDeco2.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 20%;
}
.productsDeco3 {
  background-image: url("./../../assets/Svg/ProductsDeco3.svg");
  background-repeat: no-repeat;
  background-position-x: 85%;
  background-position-y: 40%;
}

}
@media only screen and (max-width: 1024px) {
  .productsbg1 {
  background-image: url("./../../assets/images/ProductsGroup.png");
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: top;
  background-size: 50%;
}
}
@media only screen and (max-width: 640px) {
  .productsbg1 {
  background-image: url("./../../assets/images/ProductsGroup.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;
  background-size: 100%;
}
}
</style>