<template>
  <div>
    <div class="grid md:grid-cols-3 place-items-center pb-20">
      <div>
        <div>
          <select
            class="
              class=
              cursor-pointer
              w-72
              border-2 border-StemYellow
              px-2
              bg-transparent
              StemTextSecond text-lg
              font-normal
              h-14
            "
          >
            <option value="1">Kurset</option>
            <option value="2">SQ</option>
            <option value="3">MK</option>
          </select>
        </div>
      </div>
      <div class="">
        
        <div>
          
        </div>
      </div>
      <div>
       
        <div>
         
        </div>
      </div>
    </div>
    <div class="productsDeco5">
      <div class="grid grid-flow-row gap-y-5 md:grid-cols-2 lg:grid-cols-3 md:grid-flow-row lg:grid-flow-ro place-items-center md:gap-y-24">
        <legoProductCards v-for="i of items" :key="i" :data="i"></legoProductCards>
      </div> 
    </div>
  </div>
  <div class="productsDeco4 h-20"></div>
  <Popup></Popup>
</template>

<script>
import legoProductCards from "../base/legoProductCards.vue";
import { mapActions } from 'vuex'
import products from "@/data/products";
import Popup from "@/components/base/Popup"
export default {
  components: { legoProductCards,Popup },
    data() {
    return {
      items: products.data,
    }
  },
    methods: {
    ...mapActions(["addProductToCart", 'removeProductFromCart']),
    addTocart(product) {
      this.addProductToCart(product);
    },
  },

};
</script>

<style>
.productsDeco4 {
  background-image: url("./../../assets/Svg/ProductsDeco4.svg");
  background-repeat: no-repeat;
  background-position-x: 7%;
  background-position-y: 50%;
  z-index: -1;
}
.productsDeco5 {
  background-image: url("./../../assets/Svg/ProductsDeco1.svg");
  background-repeat: no-repeat;
  background-position-x: 4%;
  background-position-y: 45%;
}
</style>