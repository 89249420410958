import products1 from "@/assets/images/LegoProducts.png";
import products2 from "@/assets/images/products2.png";
import products3 from "@/assets/images/products3.png";
import products4 from "@/assets/images/products4.png";
import products5 from "@/assets/images/products5.png";
import products6 from "@/assets/images/products6.png";

const data = [
    {
        id: 0,
        image: products1,
        title: "LEGO® Early Simple Machines",
        price: "50",
        shortDesc: `"Students are encouraged to pose ‘What if…?’ questions"`,
        longDesc:"The Early Machines Set is ideal for introducing youngsters to mechanical principles such as gears, levers, pulleys, wheels and axles, as well as investigating energy, buoyancy and balance. The set includes inspirational inbox cards for building eight different models such as the Measuring Car and the Spinning Top. Exclusive for this set is a plastic punch-out sheet with eyes, sails, scales and wings. LEGO Education STEM solutions enable young students to behave as young scientists, by providing them with tools and tasks that promote scientific enquiry. Using our solutions, students are encouraged to pose ‘What if…?’ questions. They make predictions, test the behavior of their models, and then record and present their findings.",
        duration:"4", //weeks
        totalHours: "35", //Total Hours
        courseBegin: "Jan, Feb", //Registration time
        learningDays:"Saturday", //Week day that course is tought
        learningHours:"16:00 - 20:00", //day hours that course is tought
        tabLearn:"you will learn smthing1",
        tabTeacher:"Ramadan Aliti",
    },
    {
        id: 1,
        image: products2,
        title: "LEGO® Education EV3 Core Set",
        price: "100",
        shortDesc: "Missing",
        longDesc: "This set contains everything you need to start teaching STEM and computer science using the exciting LEGO® MINDSTORMS® Education EV3 concept. It offers full teacher support, including STEM and computing teaching materials, and a comprehensive eLearning program. The system includes the Intelligent EV3 Brick, a compact and powerful programmable computer that makes it possible to control motors and collect sensor feedback using the intuitive icon-based programming and data logging software that is delivered with the set. The set is delivered in a sturdy storage bin with a sorting tray, three Servo Motors, five Sensors (Gyro, Ultrasonic, Color and 2x Touch), a Rechargeable Battery, connecting cables, and Building Instructions.",
        duration:"4", //weeks
        totalHours: "35", //Total Hours
        courseBegin: "Jan, Feb", //Registration time
        learningDays:"Saturday", //Week day that course is tought
        learningHours:"16:00 - 20:00", //day hours that course is tought
        tabLearn:"you will learn smthing1",
        tabTeacher:"Ramadan Aliti",
    },
    {
        id: 2,
        image: products3,
        title: "LEGO® Education Simple Machines Set",
        price: "200",
        shortDesc: "LEGO Education STEM solutions enable students to work as young scientists and engineers",
        longDesc: `Features 16 principle models, four main models and four problem-solving models that enable students to investigate and understand the operation of simple and compound machines found in everyday life: gears, wheels and axles, levers and pulleys.
        A perfect system for teaching the key curriculum areas of simple and compound machines such as gears, wheels and axels, levers and pulleys.
        Principle activities leads to four main activities and four problem-solving tasks that students find interesting and compelling.
        Teacher's guide provides everything needed for easy in-depth introduction, such as teacher's notes, teaching sequences, and student worksheets.
        Includes Images for Class Use, a collection of photographs, pictures, drawings, and illustrations, which can be used to support your teaching.
        Building instructions feature an element overview for easier model build and easy classroom management.
        Provides the next step from Early Simple Machines or a stepping stone for working with Simple and Powered Machines.
        Activity Pack features a flow of 16 principle activities, four main activities and four problem-solving activities. Includes inspiring teaching sequence with teachers notes, student worksheets, images for classroom use, classroom management tips, glossary, and element survey.`,
        duration:"4", //weeks
        totalHours: "35", //Total Hours
        courseBegin: "Jan, Feb", //Registration time
        learningDays:"Saturday", //Week day that course is tought
        learningHours:"16:00 - 20:00", //day hours that course is tought
        tabLearn:"you will learn smthing1",
        tabTeacher:"Ramadan Aliti",
    },
    {
        id: 3,
        image: products4,
        title: "LEGO® Education SPIKE Prime Set",
        price: "250",
        shortDesc: "Growing critical thinking skills through complex, engaging and personally relevant STEAM challenges",
        longDesc:`The LEGO® Education SPIKE™ Prime Set is the go-to STEAM learning tool for grade 6-8 students. Combining colorful LEGO building elements, easy-to-use hardware, and an intuitive drag-and-drop coding language based on Scratch, SPIKE Prime continuously engages students through playful learning activities to think critically and solve complex problems, regardless of their learning level. From easy-entry projects to limitless creative design possibilities, including the option to explore text-based coding with Python, SPIKE Prime helps students learn the essential STEAM and 21st century skills needed to become the innovative minds of tomorrow... while having fun! 
        • Sturdy storage box with sorting trays, programmable Hub, Distance Sensor, Force Sensor, Color Sensor, Large Motor, 2 Medium Motors and over 500 appealing LEGO® Technic™ elements in a fresh color palette. 
        • Programmable Hub features a 5x5 light matrix, 6 input/output ports, integrated 6-axis gyro, speaker, Bluetooth connectivity and rechargeable battery. 
        • The drag-and-drop coding environment for tablets and computers is rooted in the popular coding language based on Scratch used by millions of children around the world. 
        • Engage students with 3 STEAM unit plans for grades 6-8 focused on Engineering and Computer Science, linked to curriculum standards, and mostly optimized for 45-minute sessions. Includes comprehensive online lesson plans and teacher support. `,
        duration:"4", //weeks
        totalHours: "35", //Total Hours
        courseBegin: "Jan, Feb", //Registration time
        learningDays:"Saturday", //Week day that course is tought
        learningHours:"16:00 - 20:00", //day hours that course is tought
        tabLearn:"you will learn smthing1",
        tabTeacher:"Ramadan Aliti",
    },
    {
        id: 4,
        image: products5,
        title: "LEGO® Education Simple And Powered Machines Set",
        price: "470",
        shortDesc: "Children are encouraged to involve themselves in real world investigations and problem-solving.",
        longDesc:`The core set in our range of Simple And Powered Machines solutions. This set includes a large set of bricks and full-color building instructions for 18 main models and for 10 principle models.
        Students start by learning the basic mechanical principles behind all six simple machines – and then increase their understanding by adding complex mechanisms such as gears and cams, and even a working motor.
        Combine the Simple & Motorized Mechanisms Base Set with the Introducing Simple & Motorized Mechanisms Curriculum and the Advanced Powered Machines Curriculum and you’ve got all of the activities, bricks, elements and resources to build and explore real life machines & mechanisms in a hands-on, engaging way.
        The set includes 396 LEGO Technic elements for creating a broad range of models that cover concepts such as: forces and motion, measuring, energy and structure, all in a sturdy storage bin that includes a sorting tray.
        A motor is also included to allow students to build self-propelled machines – adding a totally new dimension of teaching opportunities to the solution.
        Using the Simple & Motorized Mechanisms solutions, students design and make models and then observe the behavior of their models. They reflect and re-design the functionality of their models, and then record and present their findings. Students build the models together, each focusing on their part of the model – strengthening their 21st-century skills in collaboration and communication.`,
        duration:"4", //weeks
        totalHours: "35", //Total Hours
        courseBegin: "Jan, Feb", //Registration time
        learningDays:"Saturday", //Week day that course is tought
        learningHours:"16:00 - 20:00", //day hours that course is tought
        tabLearn:"you will learn smthing1",
        tabTeacher:"Ramadan Aliti",
    },
    {
        id: 5,
        image: products6,
        title: "LEGO® Education WeDo2.0 ™ Core Set",
        price: "780",
        shortDesc: "Robotics for Early Years; Ignite children's curiosity and enhance their skills in science, technology, engineering and coding.",
        longDesc:`WeDo 2.0 supports you all the way by delivering an inspiring and complete teaching resource that builds students' confidence to ask questions, define problems, and design their own solutions.
        WeDo 2.0 features relevant, classroom-friendly technology to enhance your science lessons. It features simple and colorful drag-and-drop programming, a Get Started Project, a digital documentation tool that supports the collection of information and is desktop and tablet compatible.
        The core set serves two students, provides a building experience that promotes experimentation, and includes:
        • WeDo 2.0 Smarthub.
        • Medium Motor.
        • Motion and tilt sensors.
        • Plenty of LEGO® System elements.
        • Storage bin including sorting tray with labels.
        
        Also includes the WeDo 2.0 Curriculum Pack
        The teaching guide allows you to teach across the various science systems with a single platform. 
        The GET STARTED PROJECTS introduce students to the hardware and coding with the building and programming of Milo, a space rover.
        
        There are 12 GUIDE PROJECTS and 12 OPEN PROJECTS to develop problem solving, critical thinking, communication and collaboration skills while strengthening computational thinking:
        
        LIFE SCIENCE- life cycles, habitats, environment.
        ENGINEERING- design projects, prototyping and testing.
        PHYSICAL SCIENCE- forces, motion, cause & effect.
        EARTH & SPACE- climates and weather patterns`,
        duration:"4", //weeks
        totalHours: "35", //Total Hours
        courseBegin: "Jan, Feb", //Registration time
        learningDays:"Saturday", //Week day that course is tought
        learningHours:"16:00 - 20:00", //day hours that course is tought
        tabLearn:"you will learn smthing1",
        tabTeacher:"Ramadan Aliti",
    },
];

export default { data };