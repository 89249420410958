<template>
  <div class="grid grid-flow-row shadow-2xl w-min bg-white">
    <div class=" p-5">
      <img :src="data.image" alt="">
    </div>
    <div class="grid justify-items-start px-4">
      <div class="grid grid-flow-col pt-6 gap-4">
        <h1 class="text-xs font-bold text-green-500">Deskripcioni i kursit</h1>
        <div class="rounded-lg bg-blue-800 text-white border text-xs px-1">
          7-12 vjec
        </div>
      </div>
      <div class="">
        <h1 class="text-StemBlue text-lg py-2">{{ data.title }}</h1>
      </div>
      <div class="">
        <p class="text-xs text text-gray-600 text-left">
           {{ data.shortDesc }}
        </p>
      </div>
      <div class=" pt-1 pb-3">
        <p class="text-StemYellow text-sm font-bold">{{ data.price }} MKD</p>
      </div>
      <div class="grid justify-self-center pb-7">
        <!-- <a href="/ProductsDescription"> -->
        <div>
          <button
            @click="$router.push({ path: `/product_description/${data.id}` })"
            class=" 
              rounded-lg
              hover:bg-StemYellow
              bg-transparent
              text-StemYellow
              hover:text-white
              border border-StemYellow
              text-base
              font-normal
              w-64
              h-14
            "
          >
            See More
          </button>
          </div>
          <div class="pt-7">
          <button
            v-on:click="addTocart(data)"
            @click="onToggle"
            class=" 
              rounded-lg
              hover:bg-StemLightRed
              bg-transparent
              hover:text-white 
              border-StemLightRed
              border text-StemLightRed
              text-base
              font-normal
              w-64
              h-14
            "
          >
            Add to cart
          </button>
          </div>
        <!-- </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  methods: {
    ...mapActions(["addProductToCart", 'removeProductFromCart']),
    addTocart(product) {
      this.addProductToCart(product);
    },
  },
  props: ["data"],
   
};
</script>
<style>
.legobg1 {
  background-image: url("./../../assets/images/LegoProducts.png");
  background-repeat: no-repeat;
  height: 203px;
  width: 288px;
}

</style>