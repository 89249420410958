<template>
    <transition name="fade">
      <div v-if="popupVisible">
        <div
          
          class="fixed bg-black opacity-70 inset-0  z-0"
        ></div>
        <div
          class="w-screen h-56 p-3 fixed top-0 left-0  mx-auto my-auto rounded-xl shadow-lg bg-white"
        >
          <div>
            <div class="grid grid-flow-row place-items-center p-3 leading-6">
              
                  <img class="w-20 h-20" src="./../../assets/Svg/success.svg" alt="">
              
              
              <h2 class=" text-StemTextSecond text-2xl font-bold py-4">Item Added to Cart!</h2>
              
            </div>
            <div class="p-3 mt-2 text-center space-x-4 md:block">
              
              <button
                @click="closePopup"
                class="mb-2 md:mb-0 bg-StemYellow border border-StemYellow px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg hover:bg-white hover:text-StemYellow"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
</template>

<script>

import {mapGetters,mapActions} from "vuex";
export default {

methods: {
    ...mapActions(['closePopup'])
},
computed:{
    ...mapGetters(['popupVisible'])
}
}
</script>

<style scoped>
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}

.fade-leave-to {
  opacity: 0;
}
</style>