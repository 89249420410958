<template>
<div class="bg-white">
  <NavBarNew></NavBarNew>
  <ProductsHero></ProductsHero>
  <OurProductsCardsComponent></OurProductsCardsComponent>

  <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBarNew from "@/components/HomeScreen/NavBarNew.vue";
import OurProductsCardsComponent from "@/components/ProductsScreen/OurProductsCardsComponent.vue";
import ProductsHero from "@/components/ProductsScreen/ProductsHero.vue";
import Footer from "@/components/HomeScreen/Footer.vue";
//import Header from '@/components/HomeScreen/header.vue'

export default {
  name: "Our Products",
  components: {
    NavBarNew,
    OurProductsCardsComponent,
    ProductsHero,
    Footer,
  },
};
</script>